




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../layouts/components/EntityCrud.vue";
import sentryBindingModel from "../models/sentry_binding.model";

@Component({
  components: { EntityCrud },
})
export default class SentryBindings extends Vue {
  model: any = sentryBindingModel;

  title = "Alertas Sentry";
  tableColumns = ["ruleName", "groupChatTopic", "channelName"];

  formColumns = ["ruleName", "groupChatTopic", "channelName"];
  defaultEntity = {};
}
